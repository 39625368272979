import React from "react"
import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import { Swiper } from "../../utils/Swiper"
import { Scroller } from "../../utils/Scroller"
import "./work.scss"
import { Link } from "gatsby"
import StyledSaefongFishBackgroundSection from "../../components/backgroundImages/SaefongFishBackgroundSection"
import StyledCyborgGirlBackgroundSection from "../../components/backgroundImages/CyborgGirlBackgroundSection"

class Work extends React.Component {
  scroller: Scroller
  swiper: Swiper

  wheelEvent = (event: WheelEvent) => {
    this.scroller.listenScroll(event)
  }

  swipeUpEvent = () => {
    this.scroller.scroll(1)
  }

  swipeDownEvent = () => {
    this.scroller.scroll(-1)
  }

  keyDownEvent = (event: KeyboardEvent) => {
    switch (event.key) {
      case "ArrowDown":
        return this.scroller.scroll(1)
      case "ArrowUp":
        return this.scroller.scroll(-1)

      default:
        return
    }
  }

  componentDidMount() {
    //note that the scroller will create list items based on the amount of sections
    //so need to create the same amount of sections in order for ALL of the to show up
    this.scroller = new Scroller(["SEKKEI", "\nSAEFONG OUTDOORS"])
    this.swiper = new Swiper()

    document.addEventListener("wheel", this.wheelEvent, false)
    document.addEventListener("swipeUp", this.swipeUpEvent, false)
    document.addEventListener("swipeDown", this.swipeDownEvent, false)
    document.addEventListener("keydown", this.keyDownEvent, false)

    //scroll to top of the first selection
    this.scroller.scrollToCurrentSection()

    //put overflow: hidden on body so that the whole thing works
    document.body.classList.add("no-overflow")
  }

  //clean up the created events
  componentWillUnmount() {
    document.removeEventListener("wheel", this.wheelEvent)
    document.removeEventListener("swipeUp", this.swipeUpEvent)
    document.removeEventListener("swipeDown", this.swipeDownEvent)
    document.removeEventListener("keydown", this.keyDownEvent)

    //reset overflow hidden back to default
    document.body.classList.remove("no-overflow")
  }

  render() {
    return (
      <Layout>
        <SEO title="Work" />

        <div id="scrollWindow">
          <section className="imageSection">
            <StyledCyborgGirlBackgroundSection>
              <div className="enterText">
                <Link to="/work/sekkei">
                  <div>
                    <i>
                      <u>Enter</u>
                    </i>
                    <br />
                    <div className="enterTitle">SEKKEI BRANDING</div>
                  </div>
                </Link>
              </div>
            </StyledCyborgGirlBackgroundSection>
          </section>

          <section className="imageSection">
            <StyledSaefongFishBackgroundSection>
              <div className="enterText">
                <Link to="/work/saefong">
                  <div>
                    <i>
                      <u>Enter</u>
                    </i>
                    <br />
                    <div className="enterTitle">SAEFONG OUTDOORS</div>
                  </div>
                </Link>
              </div>
            </StyledSaefongFishBackgroundSection>
          </section>
        </div>

        {/* scroller stuff */}
        <aside id="scrollerNavigation">
          <div className="child" id="scrollerText">
            WORK
          </div>
        </aside>
      </Layout>
    )
  }
}

export default Work
