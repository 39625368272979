/*
Author: Kyle Yu

Date Modified: 1-13-2021

Description: 
For use in the pages/work.tsx page. Handles changing the color of the circles and keeping track of what 
image we're currently on.
*/

import { getHeightOfHeader } from "./getHeightOfHeader"

export class Scroller {
  entries: string[]
  isThrottled: boolean
  sections: HTMLCollectionOf<Element>
  currentSectionIndex: number
  navigationContainer: HTMLElement | null

  constructor(entries: string[]) {
    this.entries = entries

    this.sections = document.getElementsByClassName("imageSection")

    const sectionsArr = Array.from(this.sections)

    const currentSectionIndex = sectionsArr.findIndex(this.isScrolledIntoView)

    this.currentSectionIndex = Math.max(currentSectionIndex, 0)

    //variable gives a little "lagback" to the scroll so that you can't just scroll a little bit
    //and jump to the bottom on accident
    this.isThrottled = false

    this.drawNavigation()
  }

  isScrolledIntoView(el: HTMLElement) {
    const rect = el.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = Math.floor(rect.bottom)

    //subtract navbar height
    const isVisible =
      elemTop >= 0 && elemBottom - getHeightOfHeader() <= window.innerHeight

    return isVisible
  }

  listenScroll(event: WheelEvent) {
    if (this.isThrottled) return
    this.isThrottled = true

    setTimeout(() => {
      this.isThrottled = false
    }, 200)

    const direction = event.deltaY > 0 ? 1 : -1

    this.scroll(direction)
  }

  scroll(direction: number) {
    if (direction === 1) {
      const isLastSection =
        this.currentSectionIndex === this.sections.length - 1
      if (isLastSection) return
    } else if (direction === -1) {
      const firstSection = this.currentSectionIndex === 0
      if (firstSection) return
    }

    this.currentSectionIndex = this.currentSectionIndex + direction

    this.scrollToCurrentSection()
  }

  scrollToCurrentSection() {
    //highlight the dot on the right
    this.selectActiveNavItem()

    //scroll to the element
    this.sections[this.currentSectionIndex].scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  drawNavigation() {
    //find the navigation container in the DOM
    this.navigationContainer = document.getElementById("scrollerNavigation")

    const list = document.createElement("ul")
    list.className = "child"

    for (var i = 0; i < this.sections.length; i++) {
      const listItem = document.createElement("li")
      listItem.innerHTML = this.entries[i]

      //redeclare i as const so it doesn't change anymore like with var
      //clicking the circles on the right will take u to that page
      const index = i
      listItem.addEventListener("click", () => {
        this.currentSectionIndex = index

        this.scrollToCurrentSection()
      })

      const dotElement = document.createElement("div")
      dotElement.className = "dot"

      listItem.appendChild(dotElement)

      list.appendChild(listItem)
    }

    this.navigationContainer?.appendChild(list)

    this.selectActiveNavItem()
  }

  //add the active css class to the list item
  selectActiveNavItem() {
    if (this.navigationContainer) {
      const navigationItems = this.navigationContainer.querySelectorAll("li")

      navigationItems.forEach((item, index) => {
        if (index === this.currentSectionIndex) {
          item.classList.add("active")
          item.getElementsByTagName("div")[0].style.display = "inline-block"
        } else {
          item.classList.remove("active")
          item.getElementsByTagName("div")[0].style.display = "none"
        }
      })
    }
  }
}
